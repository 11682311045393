import React from "react"
import BackgroundImage from "gatsby-background-image"

const NewsroomHero = (props) => {
  
  const data = props.data

  const sources = [
    data.heroBackgroundImageMobile.fluid,
    {
      ...data.heroBackgroundImageDesktop.fluid,
      media: `(min-width: 1024px)`,
    },
  ]

  return (
    <BackgroundImage fluid={sources}>
      <div className="container mx-auto t-grid flex pt-36 pb-56 lg:py-64 lg:items-center">
        <h1 className="col-span-3 lg:col-start-1 lg:col-span-7 color-white">
          {data.heroHeading}
        </h1>
      </div>
    </BackgroundImage>
  )
}

export default NewsroomHero