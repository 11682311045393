import React from "react"
import { Link } from "gatsby"
import LeftTitle from "./lefttitle"
import NewsroomPost from "./newsroompost"
import TrendingPost from "./trendingpost"

const LatestPosts = (props) => {
  const data = props.data
  const latestPosts = data.latestPosts.newsArticles
  const trendingPosts = data.trendingPosts.newsArticles

  return (
    <div className="bg-white">
      <div className="container mx-auto flex flex-col lg:flex-row justify-between relative -top-24 space-y-8 lg:space-y-0">
        <div className="space-y-10 relative">
          <LeftTitle text={data.latestPosts.title} color="black" />
          <div className="lg:grid lg:grid-cols-3 lg:space-x-4 lg:pr-12 xl:pr-24 space-y-8 lg:space-y-0">
            {latestPosts.map(NewsroomPost)}
          </div>
          <div>
            <Link to="/archive">
              <button className="button-black cta">{data.buttonText}</button>
            </Link>
          </div>
        </div>
        <div className="space-y-10 lg:w-3/4 xl:w-1/2 flex flex-col justify-between">
          <LeftTitle text={data.trendingPosts.title} color="black" class="p-0" />
          {trendingPosts.map(TrendingPost)}
        </div>
      </div>
    </div>
  )
}

export default LatestPosts
