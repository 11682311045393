import React from "react"
import Slider from "react-slick"
import SpotlightSlide from "./spotlightslide"

var settings = {
  adaptiveHeight: false,
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        arrows: false,
      },
    },
  ],
}

const SpotlightCarousel = props => {
  return (
    <Slider {...settings} className="container mx-auto black-dots">
      {props.spotlightData.map(SpotlightSlide)}
    </Slider>
  )
}

export default SpotlightCarousel
