import React from "react"
import SpotlightCarousel from "./spotlightcarousel"
import LeftTitle from "./lefttitle"

const LatestNews = (props) => {
  const data = props.data
  const spotlightData = data.spotlightCarousel.newsArticles
  return (
    <div className="space-y-10 bg-white">
      <div className="container relative -top-32 mx-auto">
        <LeftTitle text={data.spotlightCarousel.title} className="" />
      </div>
      <div className="relative -top-32 overflow-hidden">
        <SpotlightCarousel spotlightData={spotlightData} />
      </div>
    </div>
  )
}

export default LatestNews
