import React from "react"
import LinkOutArrow from "../images/icons/linkOutArrowBlack.svg"

export default props => {
  return (
    <div className="px-4 lg:px-0">
      <span className="eyebrow">{props.category}</span>
      <h6 className="dark-1">{props.headline}</h6>
      <div className="w-full flex justify-between items-center">
        <span className="gray-2 body-3">{props.date}</span>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={props.articleLink}
          className="text-black"
        >
          <img src={LinkOutArrow} alt="link" />
        </a>
      </div>
      <hr className="light-2 border-b-0" />
    </div>
  )
}
