import React from "react"
import Img from "gatsby-image"

export default props => {
  return (
    <div className="slide">
      <div className="light-1-bg shadow-xl">
        <Img fluid={props.articleImage.fluid} className="lg:hidden" />
        <div className="lg:grid grid-cols-2 items-center lg:pt-20 p-6 lg:p-28 space-x-12">
          <div className="space-y-4">
            <span className="eyebrow">SPOTLIGHT</span>
            <h3 className="text-black">{props.headline}</h3>
            <p className="gray-3 body-2">{props.articleLead}</p>
            <div>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={props.articleLink}
                className="link text-black"
              >
                More Info
              </a>
            </div>
          </div>
          <div className="h-full w-full hidden lg:flex lg:items-center">
            <Img fluid={props.articleImage.fluid} className="w-full" />
          </div>
        </div>
      </div>
    </div>
  )
}
