import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import NewsroomHero from "../components/newsroomhero"
import LatestNews from "../components/latestnews"
import LatestPosts from "../components/latestposts"

const Newsroom = ({data}) => {
  const newsroomData = data.contentfulNewsroomPage

  return (
    <Layout>
      <SEO title="Newsroom" />

      <NewsroomHero data={newsroomData} />
      <div className="bg-white">
        <LatestNews data={newsroomData} />
        <LatestPosts data={newsroomData} />
      </div>
    </Layout>
    )
  }

export const query = graphql`
  query newsroomData {
    contentfulNewsroomPage {
      heroHeading
      buttonText
      trendingPosts {
        title
        newsArticles {
          articleImage {
            fluid {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          articleLead
          articleLink
          category
          date
          headline
        }
      }
      spotlightCarousel {
        title
        newsArticles {
          articleImage {
            fluid {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          articleLead
          articleLink
          category
          date
          headline
        }
      }
      latestPosts {
        title
        newsArticles {
          articleImage {
            fluid {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          articleLead
          articleLink
          category
          date
          headline
        }
      }
      heroBackgroundImageMobile {
        fluid {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      heroBackgroundImageDesktop {
        fluid(maxWidth:1920, quality: 85) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
  }`

export default Newsroom
